import React, { createContext, useContext } from "react";
import { useQuery } from "react-query";
import {userState, customerIdState} from "../atoms";
import { useRecoilValue } from "recoil";
import { useClient } from "./useClient";

const UserContext = createContext(null);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ ...props }) => {
  const user = useRecoilValue(userState);
  const customerId = useRecoilValue(customerIdState);
  const userId = customerId ?? user?.id ?? null;
  const client = useClient();

  const query = useQuery(["user", userId], async () => {
    if (!userId) return null;

    const resp = await client
      .post("customer-view", {
        customer_id: userId,
      })
      .get("data");

    const status = resp.status;

    if (status && status !== 200) {
      if (status === 401 || status === 600) {
        return null;
      }
      throw Error('Invalid status');
    }

    resp.customer = resp.customer[0];
    resp.customer.id = userId;
    resp.user = user;

    return resp;
  }, {
    cacheTime: 0,
    staleTime: 0,
  });

  const { data } = query;

  const value = {
    user: data,
    userId,
    query,
  };

  return <UserContext.Provider value={value} {...props} />;
};

