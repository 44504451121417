import React, { useMemo } from "react";
import { useUserContext } from "./hooks";
import numeral from "numeral";
import { produce } from "immer";
import { parse, format } from "date-fns";
import { CURRENCY_FORMAT, DATE_FORMAT, DATETIME_PARSE } from "./constants";

const api = process.env.REACT_APP_API_SERVER;

export const Invoices = () => {
  const { user } = useUserContext();
  const { invoice: _invoice } = user;

  const invoice = useMemo(
    () =>
      produce(_invoice, (draft) => {
        for (const i of draft) {
          try{

            const invoiceDate = parse(i.invoice_date, DATETIME_PARSE, new Date());
            const dueDate = parse(i.due_date, DATETIME_PARSE, new Date());
            i.invoice_date = format(invoiceDate, DATE_FORMAT);
            i.due_date = format(dueDate, DATE_FORMAT);
            i.invoice_total_amount = numeral(i.invoice_total_amount).format(
              CURRENCY_FORMAT
              );
          }
          catch{
            i.invoice_date = "N/A";
            i.due_date = "N/A";
            i.invoice_total_amount = "N/A";
          }
        }
      }),
    [_invoice]
  );

  return (
    <>
      <h2 className="element-title mb-4">Invoices</h2>
      <div className="tw-overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Invoice number</th>
              <th>Invoice date</th>
              <th>Due date</th>
              <th className="text-right">Total</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {invoice.map((i, idx) => (
              <tr key={idx}>
                <td>{i.invoice_no}</td>
                <td>{i.invoice_date}</td>
                <td>{i.due_date}</td>
                <td className="text-right">{i.invoice_total_amount}</td>
                <td>
                  <a
                    className="btn btn-link btn-sm py-0"
                    href={`${i.invoice_pdf_path}`}
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
