import React, { useMemo } from "react";
import {Alert, Card, Col} from "reactstrap";
import { Routes, Route, Navigate } from "react-router-dom";
import { MyDetails } from "./MyDetails";
import { Registrations } from "./Registrations";
import { Subscriptions } from "./Subscriptions";
import { ParkingHistory } from "./ParkingHistory";
import { Invoices } from "./Invoices";
import { Credits } from "./Credits";
import { ContactUs } from "./ContactUs";
import {useUserContext} from "./hooks";
import {FormGroup, Select} from "./components";
import {useForm} from "react-hook-form";
import {useSetRecoilState} from "recoil";
import {customerIdState} from "./atoms";
import { find, sortBy } from 'lodash';

export const Home = () => {
  const { user: data } = useUserContext();
  const { user, customer } = data;
  const linkedAccounts = useMemo(() => sortBy(
    user.linked_accounts?.map(i => ({
      label: i.child_pcp_client_id,
      value: i.child_customer_id
    })) ?? [],
    'label'
  ), [user]);

  const customerId = customer.id.toString();
  const setCustomerId = useSetRecoilState(customerIdState);

  const initialAccount = useMemo(() => {
    return find(linkedAccounts, i => i.value === customerId);
  }, [linkedAccounts, customerId]);

  const methods = useForm({
    defaultValues: {
      account: initialAccount
    }
  });
  const { control, setValue } = methods;

  return (
    <Col>
      {linkedAccounts.length ? (
        <FormGroup name="account" label="Account">
          <Select options={linkedAccounts} onChange={v => {
            setValue('account', v);
            setCustomerId(v?.value ?? null);

          }} name="account" control={control} />
        </FormGroup>
      ) : null}
      <Card body>
        {customer.status === 'inactive' ? (
          <Alert color="warning">
            This account is inactive.
          </Alert>
        ) : (
          <Routes>
            <Route path="my_details" element={<MyDetails />} />
            <Route path="registrations" element={<Registrations />} />
            <Route path="parking_history" element={<ParkingHistory />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="credits" element={<Credits />} />
            <Route path="contact_us" element={<ContactUs />} />
            <Route element={<Navigate to="my_details" replace />} />
          </Routes>
        )}
      </Card>
    </Col>
  );
};
