import React, { useMemo } from "react";
import { useClient, useUserContext } from "./hooks";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormGroup, SubmitButton } from "./components";
import { Button } from "reactstrap";
import classNames from "classnames";

export const Registrations = () => {
  const { user } = useUserContext();
  const { registrations: _registrations, customer } = user;

  const maxAllowed = Number(customer.maxallowedregno || "0");

  const registrations = useMemo(
    () => _registrations.filter((i) => i.status === "active"),
    [_registrations]
  );

  const methods = useForm({
    defaultValues: {
      registrations,
    },
  });
  const { register, control, handleSubmit } = methods;
  const client = useClient();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "registrations",
    keyName: "key",
  });

  async function onSubmit(x) {
    try {
      var letters = /^[A-Za-z0-9]+$/;
      let success = true

      x.registrations.forEach(item =>{
        if(item.vehicle_registration_number.match(letters) && success==true){
          console.log(".");
        }
        else{
          alert("You can only use alphanumeric characters for registration numbers");
          success = false
          return        
        }
        if(item.vehicle_registration_number.length > 8 && success==true){
          alert("Too many characters in your registration number");
          success = false      
          return      
        }
      })

      if(success == true){
        const payload = {
          customer_id: user.customer.id,
          regNumbers: x.registrations.map((i) => i.vehicle_registration_number),
        };
  
        const resp = await client.post("edit-reg-number", payload).get("data");
  
        if (resp?.status !== 200) {
          toast.error("Could not update registrations");
        } else {
          alert("Pending: Changes can take up to 48 working hours to process and you will be notified when the new number plate is live.");
          toast.info(
            "Changes can take up to 48 working hours to process and you will be notified when the new number plate is live.",
            {autoClose: false}
          );
        }
      }
    } catch (ex) {
      toast.error("An error occurred");
    }
  }

  return (
    <FormProvider {...methods}>
      <h2 className="element-title mb-4">Registrations</h2>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <table className="table table-sm table-borderless">
          <thead>
            <tr>
              <th>Registration number</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {fields.map((i, idx) => {
              const n = (v) => `registrations.${idx}.${v}`;

              return (
                <tr key={i.key}>
                  <td>
                    <FormGroup
                      name={n("vehicle_registration_number")}
                      getInputWrapperProps={(props) => ({
                        ...props,
                        className: classNames(props.className, "mb-0"),
                      })}
                    >
                      <input
                        name={n("vehicle_registration_number")}
                        className="input"
                        ref={register({ required: true })}
                        defaultValue={i.vehicle_registration_number}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    {fields.length > 1 ? (
                      <Button
                        color="link"
                        onClick={() => {
                          remove(idx);
                        }}
                      >
                        <span className="fa fa-trash tw-text-red-500" />
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="tw-flex tw-justify-between">
          {fields.length < maxAllowed ? (
            <button
              className="button button-secondary"
              onClick={() => {
                append({
                  vehicle_registration_number: "",
                });
              }}
            >
              <span className="fa fa-plus mr-1" /> Add another
            </button>
          ) : null}
          <div />

          <SubmitButton>Update</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
