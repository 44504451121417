import React, { useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useClient, useUserContext } from "./hooks";
import { FormGroup, SubmitButton } from "./components";
import { toast } from "react-toastify";
import { DATE_FORMAT, DATETIME_PARSE } from "./constants";
import { format, parse } from "date-fns";

export const MyDetails = () => {
  const { user, query } = useUserContext();
  const { customer, user: _user } = user;
  const methods = useForm({
    defaultValues: customer,
  });
  const { register, handleSubmit } = methods;
  const client = useClient();
  const [paymentType, createdAt] = useMemo(() => {
    const { payment_type, go_cardless_pmt_day, created_at } = _user;
    let paymentType = "";

    if (payment_type === "go_cardless") {
      paymentType = "Go Cardless";

      if (go_cardless_pmt_day) {
        paymentType += ` (payment day: ${go_cardless_pmt_day})`;
      }
    } else {
      paymentType = payment_type;
    }

    try{
      return [
        paymentType,
        format(created_at, DATETIME_PARSE),
      ];
    }catch(e){
      console.log(e);
      return[
        paymentType,
        format(new Date(), DATETIME_PARSE),
    ];
    }
    }, [_user]);

  async function onSubmit({ first_name, last_name }) {
    try {
      const resp = await client
        .post("edit-customer", {
          first_name,
          last_name,
          subscriptions: [],
          customer_id: customer.id,
        })
        .get("data");

      if (resp?.status !== 200) {
        toast.error("Could not update details at this time");
        return;
      }

      await query.refetch({ throwOnError: true });
      toast.success("Details updated successfully");
    } catch (ex) {
      toast.error("An error occurred");
    }
  }

  return (
    <FormProvider {...methods}>
      <h2 className="element-title mb-0">My details</h2>
      <p className="text-muted tw-text-sm mb-4">Joined {createdAt}</p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup name="first_name" label="First name">
          <input
            name="first_name"
            ref={register({ required: true })}
            className="input"
          />
        </FormGroup>
        <FormGroup name="last_name" label="Last name">
          <input
            name="last_name"
            ref={register({ required: true })}
            className="input"
          />
        </FormGroup>
        <FormGroup name="email" label="Email address">
          <input
            type="email"
            readOnly
            disabled
            name="email"
            ref={register()}
            className="input"
          />
        </FormGroup>
        {customer.email_second ? (
          <FormGroup label="Second email address" name="email_second">
            <input
              name="email_second"
              ref={register()}
              readOnly
              disabled
              className="input"
            />
          </FormGroup>
        ) : null}
        <FormGroup label="PCP Client ID" name="client_no">
          <input
            disabled
            name="client_no"
            ref={register}
            className="input"
            readOnly
          />
        </FormGroup>
        <FormGroup label="Company name" name="company_name">
          <input
            disabled
            name="company_name"
            ref={register}
            className="input"
            readOnly
          />
        </FormGroup>
        <FormGroup label="Payment type" name="payment_type">
          <input
            disabled
            className="input"
            defaultValue={paymentType}
            readOnly
          />
        </FormGroup>

        <div className="tw-flex tw-justify-end">
          <SubmitButton>Update</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
