import React from "react";
import { useUserContext } from "./hooks";
import { Navigate } from "react-router-dom";

export const AuthenticatedContainer = ({ children }) => {
  const { user, query } = useUserContext();
  const { isLoading } = query;

  if (isLoading) return null;

  if (!user) return <Navigate replace to="login" />;

  return children;
};
