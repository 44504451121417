import { useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useClient } from "./hooks";
import { Link, useParams } from "react-router-dom";
import { BaseA, FormGroup, Input, SubmitButton } from "./components";
import { parse, differenceInMinutes } from "date-fns";
import { Alert } from "reactstrap";

const defaultValues = {
  pcp_client_id: "",
  password: "",
  confirm_password: "",
};

export const CompleteRegistration = () => {
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });
  const { handleSubmit, formState, setError, clearErrors, errors, getValues } =
    methods;
  const { isSubmitSuccessful } = formState;
  const client = useClient();
  const { token, timestamp: timestampParam } = useParams();

  const isValidTimestamp = useMemo(() => {
    const value = atob(timestampParam);
    const dt = parse(value, "yyyy-MM-dd HH:mm:ss", new Date());
    const diff = differenceInMinutes(new Date(), dt);
    return diff < 120;
  }, [timestampParam]);

  async function onSubmit({ confirm_password, ...x }) {
    try {
      const { status, message } = await client
        .post("register", {
          ...x,
          pcp_client_id: token,
        })
        .get("data");

      if (status !== 200) {
        setError("server", {
          message,
          type: message ? undefined : "unknown",
        });
      }
    } catch (ex) {
      setError("server", {
        type: "unknown",
      });
    }
  }

  const base = (children) => (
    <BaseA>
      <div className="container">
        <div className="login-form-wrapper">
          <h1 className="section-title">Complete Registration</h1>
          {children}
        </div>
      </div>
    </BaseA>
  );

  if (!isValidTimestamp) {
    return base(
      <Alert color="danger">This link has expired, please try again.</Alert>
    );
  }

  return base(
    <>
      {isSubmitSuccessful ? (
        <div className="alert alert-info">
          Registration complete, you may now <Link to="/login" style={{ color: '#007bff'}}>login</Link>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              clearErrors("server");
              handleSubmit(onSubmit)(e);
            }}
          >
            <FormGroup label="Password">
              <Input
                id="password"
                name="password"
                type="password"
                className="input"
                rules={{
                  required: true,
                  minLength: 6,
                  validate: {
                    hasDigit: (v) => /[0-9]/.test(v),
                    hasAlpha: (v) => /[A-Za-z]/.test(v),
                  },
                }}
              />
            </FormGroup>
            <FormGroup label="Confirm password">
              <Input
                id="confirm_password"
                name="confirm_password"
                type="password"
                className="input"
                rules={{
                  required: true,
                  validate: {
                    matchesPassword: (v) => v === getValues("password"),
                  },
                }}
              />
            </FormGroup>
            {errors.server?.type === "unknown" ? (
              <div className="alert alert-danger">
                An unknown error occurred.
              </div>
            ) : null}
            {errors.server?.message ? (
              <div className="alert alert-danger">{errors.server.message}</div>
            ) : null}
            {errors.confirm_password?.type === "matchesPassword" ? (
              <div className="alert alert-danger" role="alert">
                Passwords do not match.
              </div>
            ) : null}
            {errors.password ? (
              <div className="alert alert-danger" role="alert">
                Password must be at least 6 characters and contain a mix of
                letters and numbers.
              </div>
            ) : null}
            <div className="d-flex justify-content-between align-items-center">
              <Link to="../login">Back to login</Link>
              <SubmitButton>Complete Registration</SubmitButton>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};
