import { useForm, FormProvider } from "react-hook-form";
import { useClient } from "./hooks";
import { Link } from "react-router-dom";
import { BaseA, FormGroup, Input, SubmitButton } from "./components";

const defaultValues = {
  pcp_client_id: "",
};

export const Register = () => {
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });
  const { handleSubmit, formState, setError, clearErrors, errors } = methods;
  const { isSubmitSuccessful } = formState;
  const client = useClient();

  async function onSubmit(x) {
    try {
      const { status, message } = await client.post("verify", x).get("data");

      if (status !== 200) {
        setError("server", {
          message,
          type: message ? undefined : "unknown",
        });
      }
    } catch (ex) {
      setError("server", {
        type: "unknown",
      });
    }
  }

  return (
    <BaseA>
      <div className="container">
        <div className="login-form-wrapper">
          <h1 className="section-title">Register</h1>
          {isSubmitSuccessful ? (
            <div className="alert alert-info">
              Please check your email to complete registration. Go back to{" "}
              <Link to="../login"  style={{ color: '#007bff'}}>login</Link>
            </div>
          ) : (
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => {
                  clearErrors("server");
                  handleSubmit(onSubmit)(e);
                }}
              >
                <FormGroup label="PCP Client ID">
                  <Input
                    id="pcp_client_id"
                    name="pcp_client_id"
                    className="input"
                    rules={{ required: true }}
                  />
                </FormGroup>
                {errors.server?.type === "unknown" ? (
                  <div className="alert alert-danger">
                    An unknown error occurred.
                  </div>
                ) : null}
                {errors.server?.message ? (
                  <div className="alert alert-danger">
                    {errors.server.message}
                  </div>
                ) : null}
                <div className="d-flex justify-content-between align-items-center">
                  <Link to="../login">Back to login</Link>
                  <SubmitButton>Register</SubmitButton>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
    </BaseA>
  );
};
