import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { Register } from "./Register";
import { Login } from "./Login";
import { ForgottenPassword } from "./ForgottenPassword";
import { Home } from "./Home";
import { AuthenticatedContainer } from "./AuthenticatedContainer";
import { BaseB } from "./components";
import { Logout } from "./Logout";
import { CompleteRegistration } from "./CompleteRegistration";
import { MasterAccess } from "./MasterAccess";
import { HOME_URL } from "./constants";
import {ResetPassword} from "./ResetPassword";

export const App = () => {
  return (
    <Routes basename={HOME_URL}>
      <Route path="/register" element={<Register />} />
      <Route
        path="/complete_registration/:token/:timestamp"
        element={<CompleteRegistration />}
      />
      <Route path="/master_access" element={<MasterAccess />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotten_password" element={<ForgottenPassword />} />
      <Route path="/reset_password/:token" element={<ResetPassword />} />
      <Route
        element={
          <AuthenticatedContainer>
            <BaseB>
              <Outlet />
            </BaseB>
          </AuthenticatedContainer>
        }
      >
        <Route element={<Home />} path="/*" />
        <Route path="/" element={<Navigate to="my_details" replace />} />
        <Route element={<Logout />} path="logout" />
      </Route>
    </Routes>
  );
};
