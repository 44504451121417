/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.scss";
import { App } from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Promise from "bluebird";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserContextProvider } from "./hooks";
import { ErrorBoundary } from "./ErrorBoundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BaseA } from "./components";
import numeral from "numeral";
import "numeral/locales/en-gb";

numeral.locale("en-gb");

global.Promise = Promise;
Promise.cancel = undefined;

Promise.config({
  warnings: false,
  longStackTraces: false,
  cancellation: false,
  monitoring: false,
  asyncHooks: false,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: false,
      structuralSharing: false,
      useErrorBoundary: true,
    },
  },
});

ReactDOM.render(
  <>
    <ToastContainer />
    <RecoilRoot>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary component={BaseA}>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </Router>
    </RecoilRoot>
  </>,
  document.getElementById("root")
);
