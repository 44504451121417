import { DefaultValue, selector, atom } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const tokenState = atom({
  key: "token",
  default: null,
  effects_UNSTABLE: [localStorageEffect("pcp_token")],
});

export const userState = atom({
  key: "user",
  default: null,
  effects_UNSTABLE: [localStorageEffect("pcp_user")],
});

export const customerIdState = atom({
  key: "customerId",
  default: null,
  effects_UNSTABLE: [localStorageEffect("pcp_customer_id")],
});

export const userIdSelector = selector({
  key: "userId",
  get: ({ get }) => {
    const user = get(userState);
    return user?.id ?? null;
  },
});
