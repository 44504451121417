import { QueryErrorResetBoundary } from "react-query";
import { ErrorBoundary as BaseErrorBoundary } from "react-error-boundary";
import {Card} from "reactstrap";
import { BaseA } from "./components";
import {HOME_URL} from "./constants";
import {useResetRecoilState} from "recoil";
import {customerIdState, tokenState, userState} from "./atoms";
import {useNavigate} from "react-router";

export const ErrorBoundary = ({ children }) => {
  const resetToken = useResetRecoilState(tokenState);
  const resetUser = useResetRecoilState(userState);
  const resetCustomerId = useResetRecoilState(customerIdState);
  const navigate = useNavigate()

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <BaseErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <BaseA>
              <div className="container">
                <Card body>
                  <div>
                    An unexpected error occurred. You may try the following options to resolve the problem:
                    <ul className="mt-2">
                      <li>
                        <button
                          className="btn btn-link btn-sm"
                          onClick={resetErrorBoundary}
                        >
                          Try again
                        </button>
                      </li>
                      <li>
                        <button className="btn btn-link btn-sm" onClick={() => {
                          navigate(HOME_URL);
                          resetErrorBoundary();
                        }}>
                          Go to the home page
                        </button>
                      </li>
                      <li>
                        <button
                          className="btn btn-link btn-sm"
                          onClick={() => {
                            resetToken();
                            resetCustomerId();
                            resetUser();
                            navigate(HOME_URL);
                            resetErrorBoundary();
                          }}
                        >
                          Clear application cache
                        </button>
                      </li>
                    </ul>

                  </div>
                </Card>
              </div>
            </BaseA>
          )}
        >
          {children}
        </BaseErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
