import { useForm, FormProvider } from "react-hook-form";
import { useClient } from "./hooks";
import { isEmail } from "validator";
import { Link, useNavigate } from "react-router-dom";
import { BaseA, Button, FormGroup, Input, SubmitButton } from "./components";
import {customerIdState, tokenState, userState} from "./atoms";
import { useSetRecoilState, useResetRecoilState } from "recoil";

const defaultValues = {
  email: "",
  password: "",
};

export const Login = () => {
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });
  const { handleSubmit, setError, clearErrors, errors } = methods;
  const client = useClient();
  const setToken = useSetRecoilState(tokenState);
  const setUser = useSetRecoilState(userState);
  const resetToken = useResetRecoilState(tokenState);
  const resetCustomerId = useResetRecoilState(customerIdState);
  const resetUser = useResetRecoilState(userState);

  const navigate = useNavigate();

  async function onSubmit(params) {
    try {
      resetUser();
      resetCustomerId();
      resetToken();

      const { user, message, token, linked_accounts, status } = await client
        .post("login", null, {
          params,
        })
        .get("data");

      var loginData = {
        client_no: user?.client_no,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        portal_login: true
      };
      fetch("https://portal.premiumcarparks.co.uk" + '/app_log_signin', {
        method: 'POST',
        body: JSON.stringify(loginData),
      })
        .then((r) => r.json())
        .then((rJson) => {
          console.log("success");
        })
        .catch((error) => {
          console.error(error);
        });

      const id = user?.id;
      const is_master_portal_owner = user?.is_master_portal_owner;

      if (!id || status !== 200) {
        setError("server", {
          type: "unknown",
          message: message ?? "An error occurred",
        });
        return;
      }

      user.linked_accounts = linked_accounts;

      if (is_master_portal_owner === "1") {
        setError("server", {
          type: "master",
        });
        setUser(user);
        setToken(token);
        return;
      }

      console.log(user);

      const { subscription } = await client
        .post("customer-view", {
          customer_id: id,
          token,
        })
        .get("data");

      console.log(subscription);

      const activeSubscriptions = (subscription ?? []).filter(
        (i) => i.status === "active"
      );

      console.log(activeSubscriptions);

      if (!activeSubscriptions.length) {
        setError("server", {
          type: "unknown",
          message: "At least 1 active subscription is required to log in",
        });
        return;
      }
      setUser(user);
      setToken(token);
      navigate("..");
    } catch (ex) {
      setError("server", {
        type: "unknown",
        message: "An error occurred",
      });
    }
  }

  return (
    <BaseA
      bottom={
        <div className="container">
          <div className="text-center">
            Not signed up? <Link to="../register">Register now</Link>
          </div>
          <div className="text-center tw-mt-8">
            You must be subscribed to at least 1 car park before you can create
            an account
            <br />
            <Button
              className="button button-primary mt-2"
              onClick={() => {
                window.location.href =
                  "https://portal.premiumcarparks.co.uk/signup";
              }}
            >
              Join Now
            </Button>
          </div>
        </div>
      }
    >
      <div className="container">
        <div className="login-form-wrapper">
          <h1 className="section-title">Login</h1>
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => {
                clearErrors("server");
                handleSubmit(onSubmit)(e);
              }}
            >
              <FormGroup label="Email">
                <Input
                  id="username"
                  name="email"
                  className="input"
                  rules={{ required: true, validate: isEmail }}
                />
              </FormGroup>
              <FormGroup label="Password">
                <Input
                  id="password"
                  name="password"
                  type="password"
                  className="input"
                  rules={{
                    required: true,
                  }}
                />
              </FormGroup>
              {errors.server?.message ? (
                <div className="alert alert-danger">
                  {errors.server.message}
                </div>
              ) : null}
              {errors.server?.type === "master" ? (
                <div className="alert alert-success">
                  Please verify your email to gain access to portal
                </div>
              ) : null}
              <div className="button-wrapper text-center">
                <SubmitButton className="button-block">Login</SubmitButton>
              </div>
              <div className="text-center">
                <Link to="../forgotten_password" className="forgot">
                  Forgot password?
                </Link>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </BaseA>
  );
};
