export const DATE_PARSE = "yyyy-MM-dd";

export const DATETIME_PARSE = `${DATE_PARSE} HH:mm:ss.SSS`;

export const DATE_FORMAT = "dd/MM/yyyy";

export const DATETIME_FORMAT = `${DATE_FORMAT} HH:mm`;

export const CURRENCY_FORMAT = "($0.00)";

export const HOME_URL = process.env.PUBLIC_URL || "/";
