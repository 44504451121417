import React, { useMemo, useState } from "react";
import { useUserContext } from "./hooks";
import { parse, format } from "date-fns";
import { DATE_FORMAT, DATE_PARSE } from "./constants";
import { useTable, useSortBy } from "react-table";
import { Table } from "./components";
import { filter } from "lodash";
import DatePicker from "react-datepicker";

export const ParkingHistory = () => {
  const { user } = useUserContext();
  const [date, setDate] = useState();
  const { session_history } = user;

  const data = useMemo(() => {
    let result = session_history.map((i) => ({
      ...i,
      parking_date: parse(i.parking_date, DATE_PARSE, new Date()),
    }));

    if (date) {
      result = filter(result, { parking_date: date });
    }

    return result;
  }, [session_history, date]);

  const columns = useMemo(
    () => [
      {
        accessor: "carpark_name",
        Header: "Car park",
      },
      {
        accessor: "parking_date",
        Header: "Date",
        Cell: ({ value }) => {
          return format(value, DATE_FORMAT);
        },
        sortType: (a, b) => {
          return a.original.parking_date < b.original.parking_date ? -1 : 1;
        },
      },
    ],
    []
  );

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "parking_date",
            desc: true,
          },
        ],
      },
      disableSortRemove: true,
    },
    useSortBy
  );

  return (
    <>
      <h2 className="element-title mb-4">Parking history</h2>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={date}
        className="form-control tw-w-full mb-4"
        placeholderText="Filter by date"
        onChange={setDate}
      />
      <Table table={table} />
    </>
  );
};
