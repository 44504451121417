import React, { useMemo } from "react";
import { useUserContext } from "./hooks";
import numeral from "numeral";
import { parse, format } from "date-fns";
import produce from "immer";
import { capitalize } from "lodash";
import { CURRENCY_FORMAT, DATE_FORMAT, DATETIME_PARSE } from "./constants";

export const Credits = () => {
  const { user } = useUserContext();
  const { credits: _credits, customer } = user;

  const credits = useMemo(
    () =>
      produce(_credits, (draft) => {
        for (const i of draft) {
          i.transaction_date = parse(
            i.transaction_date,
            DATETIME_PARSE,
            new Date()
          );
          let value = Number(i.credit_value);

          if (i.transaction_type === "redeem") {
            value = value * -1;
          }

          i.credit_value = value;
        }
      }),
    [_credits]
  );

  return (
    <>
      <h2 className="element-title mb-2">Credits</h2>
      <h2 className="element-title mb-0">
        <span className="tw-text-lg">Available Credits</span>
      </h2>
      <table className="tw-inline-block table table-sm table-borderless">
        <tbody>
          <tr>
            <td className="text-muted">£ value inc. VAT</td>
            <td className="tw-font-semibold text-right">
              {numeral(customer.credit).format(CURRENCY_FORMAT)}
            </td>
          </tr>
          <tr>
            <td className="text-muted">Session credits</td>
            <td className="tw-font-semibold text-right">
              {numeral(customer.session).format("0")}
            </td>
          </tr>
        </tbody>
      </table>
      <h2 className="element-title mb-4">
        <span className="tw-text-lg">Transactions</span>
      </h2>
      <div className="tw-overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th />
              <th />
              <th className="text-right">Amount</th>
              <th>Date</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {credits.map((i, idx) => (
              <tr key={idx}>
                <td>
                  {i.credit_type === "session" ? "Session Credit" : ""}
                  {i.credit_type === "credit" ? "£ Value Credit" : ""}
                  {i.refer_to ? " - Referral" : ""}
                </td>
                <td>
                  {i.transaction_type === "add"
                    ? capitalize(i.refer_status)
                    : ""}
                  {i.transaction_type === "redeem" ? "Redeemed" : ""}
                </td>
                <td className="text-right">
                  {i.credit_type === "session"
                    ? numeral(i.credit_value).format("0")
                    : ""}
                  {i.credit_type === "credit"
                    ? numeral(i.credit_value).format(CURRENCY_FORMAT)
                    : ""}
                </td>
                <td>{format(i.transaction_date, DATE_FORMAT)}</td>
                <td>{i.refer_to ? `Referral - ${i.refer_to}` : i.comment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
