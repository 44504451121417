import { useMemo } from "react";
import axios from "axios";
import { set } from "lodash";
import { useRecoilValue } from "recoil";
import { tokenState } from "../atoms";

export const useClient = () => {
  const token = useRecoilValue(tokenState);

  return useMemo(() => {
    const client = axios.create({
      baseURL: "/api",
    });

    client.interceptors.request.use((config) => {
      if (!token) return config;
      set(config, "params.token", token);
      return config;
    });

    return client;
  }, [token]);
};
