import { useForm, FormProvider } from "react-hook-form";
import { useClient } from "./hooks";
import { isEmail } from "validator";
import { Link } from "react-router-dom";
import { BaseA, FormGroup, Input, SubmitButton } from "./components";

const defaultValues = {
  email: "",
};

export const ForgottenPassword = () => {
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });
  const { handleSubmit, formState, setError, clearErrors, errors } = methods;
  const { isSubmitSuccessful } = formState;
  const client = useClient();

  async function onSubmit(params) {
    try {
      const { status, message } = await client
        .post("forget-password", null, {
          params,
        })
        .get("data");

      if (status !== 200) {
        setError("server", {
          type: message ? undefined : "unknown",
          message,
        });
      }
    } catch (ex) {
      setError("server", {
        type: "unknown",
      });
    }
  }

  return (
    <BaseA>
      <div className="container">
        <div className="login-form-wrapper">
          <h1 className="section-title">Forgotten password</h1>
          {isSubmitSuccessful ? (
            <div className="alert alert-info">
              Please check your email for further instructions. Go{" "}
              <Link to="../login">back to login</Link>
            </div>
          ) : (
            <FormProvider {...methods}>
              <form
                onSubmit={(e) => {
                  clearErrors("server");
                  handleSubmit(onSubmit)(e);
                }}
              >
                <FormGroup label="Email">
                  <Input
                    id="email"
                    name="email"
                    className="input"
                    rules={{ required: true, validate: isEmail }}
                  />
                </FormGroup>
                {errors.server?.type === "unknown" ? (
                  <div className="alert alert-danger">
                    An unknown error occurred.
                  </div>
                ) : null}
                {errors.server?.message ? (
                  <div className="alert alert-danger">
                    {errors.server.message}
                  </div>
                ) : null}

                <div className="d-flex justify-content-between align-items-center">
                  <Link to="../login">Back to login</Link>
                  <SubmitButton>Submit</SubmitButton>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
    </BaseA>
  );
};
