import React from "react";
import { Table as BaseTable } from "reactstrap";

export const Table = ({ table, ...props }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    table;

  return (
    <BaseTable {...getTableProps()} {...props}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                {column.canSort ? (
                  <span className="ml-2">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fa fa-sort-up" />
                      ) : (
                        <i className="fa fa-sort-down" />
                      )
                    ) : (
                      <i className="fa fa-sort" />
                    )}
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </BaseTable>
  );
};
