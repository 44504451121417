import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useResetRecoilState } from "recoil";
import { tokenState, userState, customerIdState } from "./atoms";

export const Logout = ({ children }) => {
  const navigate = useNavigate();
  const resetUser = useResetRecoilState(userState);
  const resetToken = useResetRecoilState(tokenState);
  const resetCustomerIdState = useResetRecoilState(customerIdState);

  useEffect(() => {
    resetUser();
    resetCustomerIdState();
    resetToken();
    navigate("..", { replace: true });
  }, [navigate, resetToken, resetUser, resetCustomerIdState]);

  return children ?? null;
};
