import BaseSelect from "react-select";
import { useController } from "react-hook-form";

export const Select = ({
  name,
  control,
  defaultValue,
  rules,
  onFocus,
  ...props
}) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules,
    onFocus,
  });

  return <BaseSelect {...field} {...props} />;
};
