import { useForm, FormProvider } from "react-hook-form";
import { useClient } from "./hooks";
import { Link } from "react-router-dom";
import { BaseA, FormGroup, Input, SubmitButton } from "./components";
import { useSetRecoilState } from "recoil";
import { userState } from "./atoms";
import { useNavigate } from "react-router";

const defaultValues = {
  pcp_client_id: "",
};

export const MasterAccess = () => {
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
  });
  const { handleSubmit, setError, clearErrors, errors } = methods;
  const client = useClient();
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  async function onSubmit({ pcp_client_id }) {
    try {
      const { status, user, linked_accounts } = await client
        .post(`confirm-login`, {
          pcp_client_id,
        })
        .get("data");

      if (status !== 200 || !user?.id) {
        setError("server", {
          type: "unknown",
        });
      } else {
        user.linked_accounts = linked_accounts;
        setUser(user);
        navigate("..");
      }
    } catch (ex) {
      setError("server", {
        type: "unknown",
      });
    }
  }

  const base = (children) => (
    <BaseA>
      <div className="container">
        <div className="login-form-wrapper">
          <h1 className="section-title">Master Access</h1>
          {children}
        </div>
      </div>
    </BaseA>
  );

  return base(
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          clearErrors("server");
          handleSubmit(onSubmit)(e);
        }}
      >
        <FormGroup label="PCP Client ID">
          <Input
            id="pcp_client_id"
            name="pcp_client_id"
            className="input"
            rules={{ required: true }}
          />
        </FormGroup>
        {errors.server?.message ? (
          <div className="alert alert-danger">{errors.server.message}</div>
        ) : null}
        {errors.server?.type === "unknown" ? (
          <div className="alert alert-danger">An unknown error occurred.</div>
        ) : null}
        <div className="d-flex justify-content-between align-items-center">
          <Link to="../login">Back to login</Link>
          <SubmitButton>Login</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
