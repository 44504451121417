import React from "react";
import { Col, Row } from "reactstrap";

export const ContactUs = () => {
  return (
    <div>
      <h2 className="element-title mb-2">Get in touch</h2>
      <p>Contact us via email or phone below</p>
      <Row form className="text-muted">
        <Col xs="auto">
          <i className="fa fa-phone" />
        </Col>
        <Col>
          <a href="tel:+443332229090">0333 222 9090</a>
        </Col>
      </Row>
      <Row form className="text-muted mt-2">
        <Col xs="auto">
          <i className="fa fa-envelope" />
        </Col>
        <Col>
          <a href="mailto:info@premiumcarparks.co.uk">
            info@premiumcarparks.co.uk
          </a>
        </Col>
      </Row>
    </div>
  );
};
