import React from "react";
import { useState, useCallback } from "react";
import { Modal as BaseModal } from "reactstrap";
import { useGetLatest } from "react-table";

export const useModal = ({ defaultIsOpen = false } = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const toggle = useCallback(() => setIsOpen((v) => !v), [setIsOpen]);

  const getLatest = useGetLatest({ isOpen, toggle });

  const Modal = useCallback(
    (props) => {
      return <BaseModal {...getLatest()} {...props} />;
    },
    [getLatest]
  );

  return {
    Modal,
    setIsOpen,
    isOpen,
    toggle,
  };
};
