import { useFormContext } from "react-hook-form";
import classNames from "classnames/dedupe";
import { Button } from "./Button";

export const SubmitButton = ({ children, disabled, className, ...props }) => {
  const context = useFormContext();
  const isSubmitting = context?.formState?.isSubmitting;

  return (
    <Button
      className={classNames("button button-primary", className)}
      type="submit"
      disabled={disabled || isSubmitting}
      pending={isSubmitting}
      {...props}
    >
      {children}
    </Button>
  );
};
