import React, { useMemo, useState } from "react";
import { useClient, useModal, useUserContext } from "./hooks";
import numeral from "numeral";
import { parse, format } from "date-fns";
import produce from "immer";
import { capitalize, keyBy, find, sortBy } from "lodash";
import { CURRENCY_FORMAT, DATE_FORMAT, DATETIME_PARSE } from "./constants";
import { useQuery } from "react-query";
import { ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import { FormGroup, SubmitButton, Select, Button } from "./components";
import { toast } from "react-toastify";

const AddAnotherCarPark = ({
  toggle,
  cities,
  subscription: subscriptionProp,
}) => {
  const methods = useForm();
  const { watch, handleSubmit } = methods;
  const city = watch("city");
  const client = useClient();
  const { userId } = useUserContext();
  const [pending, setPending] = useState();

  const { data: carParks } = useQuery({
    queryKey: ["car_parks", city],
    queryFn: () => {
      if (!city) return null;
      return client
        .post("carparks", { city_id: city.value })
        .get("data")
        .then((i) => sortBy(i, "name"));
    },
    keepPreviousData: false,
  });

  const subscription = useMemo(() => {
    if (!carParks || !city) return {};

    return keyBy(subscriptionProp, (i) => {
      if (i.cityName !== city.label) return null;
      return find(carParks, { name: i.carpark_name })?.id ?? null;
    });
  }, [carParks, city, subscriptionProp]);

  const { data: requestedCarParks, refetch } = useQuery({
    queryKey: ["requested_car_parks"],
    queryFn: async () => {
      const { carparks } = await client
        .post("get-requested-carparks", { customer_id: userId })
        .get("data");
      return keyBy(carparks, (i) => Number(i.fk_carpark_id));
    },
  });

  const onRequestAccess =
    (carPark) =>
    async ({ city }) => {
      const { id } = carPark;
      setPending(id);

      try {
        await client
          .post("request-carpark", {
            customer_id: userId,
            carpark_id: carPark.id,
            city_id: city.value,
            status: "requested",
          })
          .get("data");
        await refetch();
        toast.success("Car park successfully requested");
      } catch (ex) {
        toast.error("An error occurred");
        throw ex;
      } finally {
        setPending(null);
      }
    };

  const onCancelRequest =
    (carPark) =>
    async ({ city }) => {
      const { id } = carPark;
      setPending(id);

      try {
        await client
          .post("request-carpark", {
            customer_id: userId,
            carpark_id: carPark.id,
            city_id: city.value,
            status: "cancelled",
          })
          .get("data");
        await refetch();
        toast.success("Car park request cancelled");
      } catch (ex) {
        toast.error("An error occurred");
        throw ex;
      } finally {
        setPending(null);
      }
    };

  return (
    <FormProvider {...methods}>
      <ModalHeader>Add another car park</ModalHeader>
      <ModalBody>
        <FormGroup name="city" label="Centre">
          <Select
            options={cities}
            name="city"
            defaultValue={null}
            rules={{ required: true }}
          />
        </FormGroup>
        {carParks ? (
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Car park</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {carParks.map((i) => (
                <tr key={i.id}>
                  <td>{i.name}</td>
                  <td>
                    {subscription[i.id]
                      ? "Already subscribed"
                      : requestedCarParks[i.id]
                      ? capitalize(requestedCarParks[i.id].status)
                      : "Not subscribed"}
                  </td>
                  <td className="tw-w-1 tw-whitespace-nowrap">
                    {!subscription[i.id] ? (
                      requestedCarParks[i.id]?.status === "requested" ? (
                        <Button
                          pending={pending === i.id}
                          disabled={pending}
                          className="btn btn-link btn-sm"
                          onClick={handleSubmit(onCancelRequest(i))}
                        >
                          Cancel request
                        </Button>
                      ) : (
                        <Button
                          pending={pending === i.id}
                          disabled={pending}
                          className="btn btn-link btn-sm"
                          onClick={handleSubmit(onRequestAccess(i))}
                        >
                          Request access
                        </Button>
                      )
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </FormProvider>
  );
};

export const Subscriptions = () => {
  const { user } = useUserContext();
  const { subscription: _subscription } = user;
  const client = useClient();
  const { Modal, toggle, isOpen } = useModal();

  const subscription = useMemo(
    () =>
      produce(_subscription, (draft) => {
        for (const i of draft) {
          i.from_date = parse(i.from_date, DATETIME_PARSE, new Date());
          i.to_date = parse(i.to_date, DATETIME_PARSE, new Date());
        }
      }),
    [_subscription]
  );

  const { data: cities } = useQuery({
    queryKey: "cities",
    queryFn: () =>
      client
        .get("cities")
        .get("data")
        .map((i) => ({
          label: i.name,
          value: i.id,
        }))
        .then((i) => sortBy(i, "label")),
  });

  return (
    <>
      {isOpen ? (
        <Modal size="lg">
          <AddAnotherCarPark
            toggle={toggle}
            cities={cities}
            subscription={subscription}
          />
        </Modal>
      ) : null}
      <h2 className="element-title mb-4">Subscriptions</h2>
      <div className="tw-overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Car park</th>
              <th>City</th>
              <th>Subscription type</th>
              <th>Start date</th>
              <th>End date</th>
              <th className="text-right">Rate</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {subscription.map((i, idx) => (
              <tr key={idx}>
                <td className="tw-whitespace-nowrap">{i.carpark_name}</td>
                <td className="tw-whitespace-nowrap">
                  {capitalize(i.cityName)}
                </td>
                <td>{i.sub_type}</td>
                <td>{format(i.from_date, DATE_FORMAT)}</td>
                <td>
                  {i.sub_type === "SEASON TICKET (ST)"
                    ? format(i.to_date, DATE_FORMAT)
                    : ""}
                </td>
                <td className="text-right">
                  {numeral(i.rate).format(CURRENCY_FORMAT)}
                </td>
                <td>{capitalize(i.status)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="tw-flex tw-justify-end">
          <SubmitButton onClick={toggle}>Add another car park</SubmitButton>
        </div>
      </div>
    </>
  );
};
