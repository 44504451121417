import React from "react";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";
import classNames from "classnames";

export const FormGroup = ({
  label,
  name: nameProp,
  getFormGroupProps = (v) => v,
  getLabelProps = (v) => v,
  getInputWrapperProps = (v) => v,
  children,
}) => {
  const htmlFor = children?.props?.id;
  const methods = useFormContext();
  const name = nameProp ?? children?.props?.name ?? undefined;
  const hasError = name && methods && get(methods, `errors.${name}`);

  return (
    <div {...getFormGroupProps()}>
      {label ? (
        <label
          {...getLabelProps({
            htmlFor,
            className: "input-label",
          })}
        >
          {label}
        </label>
      ) : null}
      <div
        {...getInputWrapperProps({
          className: classNames("input-wrapper", hasError ? "error" : ""),
        })}
      >
        {children}
      </div>
    </div>
  );
};
