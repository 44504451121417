import { useController, useFormContext } from "react-hook-form";
import classNames from "classnames";

export const Input = ({
  tag: Tag = "input",
  name,
  defaultValue,
  control,
  rules,
  className,
  ...props
}) => {
  const methods = useFormContext();
  const { field, meta } = useController({
    defaultValue,
    control: control || methods.control,
    name,
    rules,
  });

  if (meta.invalid) {
    className = classNames(className, "is-invalid");
  }

  return <Tag {...field} className={className} {...props} />;
};
